import React, { Component } from "react";

import Accordion from "react-bootstrap/Accordion";

class Foot extends Component {
  render() {
    return (
      <div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item>
            <Accordion.Header>
              <div>
                <div>
                  <strong>Version 0.4.0 </strong>
                </div>
                <div>
                  <small>What's New</small>
                </div>
              </div>
              {/*   */}
            </Accordion.Header>
            <Accordion.Body>
              What's new in this version:
              <br />
              <ul>
                <li>Type to search...</li>
                <li>then click to add</li>
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
    );
  }
}

export default Foot;
